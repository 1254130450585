import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { SectionVideo } from 'inno-components'
import { compose } from 'recompose'
import { withSize } from 'react-sizeme'
import Config from '../../Config'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
  },
  containerLogo: {
    width: '100%',
    backgroundColor: '#000',
  },
  logo: {
    margin: '1em auto',
  },
  containerVideo: {
    position: 'relative',
  },
  triangleRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '5vw',
    backgroundImage: `linear-gradient(${Config.colors.quaternary},${
      Config.colors.quaternary
    })`,
    transform: 'skewY(-6deg)',
    transformOrigin: 'bottom right',
    zIndex: 9999999,
  },
  triangleLeft: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '5vw',
    backgroundImage: `linear-gradient(${Config.colors.quaternary},${
      Config.colors.quaternary
    })`,
    transform: 'skewY(-174deg)',
    transformOrigin: 'bottom left',
    zIndex: 9999999,
  },
  section: {
    zIndex: 2,
  },
})
class Home extends Component {
  render() {
    const height = this.props.size.width / (16 / 9)
    return (
      <SectionVideo
        height={height}
        minHeight={height}
        width={this.props.size.width}
        className={css(styles.section)}
        src="https://firebasestorage.googleapis.com/v0/b/innobelge-websites.appspot.com/o/sites%2Fraypath%2Fpublic%2Fvideos%2FVide%CC%81o%20Main%20Page%20texe%20bleu%20fonce%CC%81.mp4?alt=media&token=1bf6713b-7cb9-4ab0-95fa-4370b970c993"
      />
    )
  }
}

const ehnance = compose(withSize())
export default ehnance(Home)
