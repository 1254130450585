import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import shortid from 'shortid'
import {
  Button,
  Section,
  Grid,
  ImageBasic,
  SliderBasic,
  MaterialSelect,
  MaterialMenuItem,
  MediaQueries
} from 'inno-components'

import { withNamespaces } from 'react-i18next'

import Config from '../../Config'
// import Button from '../../../../commons/components/buttons/Button'

// import Button from '../../../../commons/components/buttons/Button'
import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'
import NosReferencesItem from './NosReferencesItem'
import Classes from '../../styles/classes'

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 2 } },
  { breakpoint: 1024, settings: { slidesToShow: 5 } }
]

const selectItems = []

const styles = StyleSheet.create({
  sectionReference: {
    backgroundColor: '#C9F1FE',
    paddingBottom: '4em'
  },
  sectionPresse: {
    background: Config.colors.thirdary
  },
  title: {
    fontSize: '1.5em',
    lineHeight: '1.5em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '3em',
    color: Config.colors.primary
  },
  selectMenu: {
    color: Config.colors.primary
  },
  inkbar: {
    ':after': {
      backgroundColor: Config.colors.primary
    }
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '0.2em',
    margin: 0,
    color: '#fff',
    height: 'auto',
    fontWeight: 'bold',
    border: 'solid 2px #fff',
    backgroundColor: 'rgba(0,0,0,0)',
    [MediaQueries.small]: {
      padding: '2em',
      margin: 0
    }
  },
  triangle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(#C9F1FE, #C9F1FE)',
    transform: 'skewY(-6deg)',
    transformOrigin: 'top left'
  },
  titrePresse: {
    fontSize: '1.5em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    textAlign: 'center',
    color: '#fff',
    display: 'block'
  }
})

class NosReferences extends Component {
  constructor(props) {
    super(props)

    const references = props.t('references:references', { returnObjects: true })
    this.state = {
      activeItem: 'administration',
      references,
      referenceActive: references.refsAdministrationsPubliques
    }
  }

  componentDidMount() {}

  onChange = (e) => {
    const { value } = e.target
    const { references } = this.state
    let { referenceActive } = this.state

    if (value === 'administration') {
      referenceActive = references.refsAdministrationsPubliques
    } else if (value === 'enseignement') {
      referenceActive = references.refsEnseignements
    } else if (value === 'nettoyage') {
      referenceActive = references.refsNettoyage
    } else if (value === 'horeca') {
      referenceActive = references.refsHoreca
    } else if (value === 'autres') {
      referenceActive = references.refsAutres
    }
    this.setState({
      activeItem: value,
      referenceActive
    })
  }

  getFormatedRefs = () => {
    const { referenceActive } = this.state
    const { images } = this.props

    return referenceActive.map(ref => (
      <div key={ref.image}>
        <NosReferencesItem reference={ref} image={images[ref.image]} />
      </div>
    ))
  }

  render() {
    const { activeItem } = this.state
    const { t } = this.props
    return (
      <div style={{ position: 'relative' }}>
        <div className={css(styles.triangle)} />
        <Section className={css(Classes.section, styles.sectionReference)}>
          <h3 className={css(styles.title)}>
            {`${t('home:nos-references.titre')} : `}
            <MaterialSelect
              classes={{ selectMenu: css(styles.selectMenu) }}
              inputclasses={{ inkbar: css(styles.inkbar) }}
              items={selectItems}
              onChange={this.onChange}
              value={activeItem}
            >
              <MaterialMenuItem value="administration">
                {t('home:nos-references.choix.administrations-publiques')}
              </MaterialMenuItem>
              <MaterialMenuItem value="enseignement">
                {t('home:nos-references.choix.enseignements')}
              </MaterialMenuItem>
              <MaterialMenuItem value="nettoyage">
                {t('home:nos-references.choix.entreprises-de-nettoyage')}
              </MaterialMenuItem>
              <MaterialMenuItem value="horeca">
                {t('home:nos-references.choix.horeca')}
              </MaterialMenuItem>
              <MaterialMenuItem value="autres">
                {t('home:nos-references.choix.autres')}
              </MaterialMenuItem>
            </MaterialSelect>
          </h3>
          <SliderBasic
            items={[]}
            infinite={false}
            responsive={responsive}
            slidesToShow={4}
            slidesToScroll={2}
          >
            {this.getFormatedRefs()}
          </SliderBasic>
        </Section>
        <Section className={css(Classes.section, styles.sectionPresse)}>
          <h3 className={css(styles.titrePresse)}>
            {t('home:nos-references.la-presse-parle-de-nous')}
          </h3>
          <Grid>
            <Grid.Row stretched centered>
              <Grid.Column width={3}>
                <Link page={pagesInfos.ComponentReferencesPresseInternet}>
                  <Button className={css(styles.button)}>
                    {t('home:nos-references.boutons.0')}
                  </Button>
                </Link>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link page={pagesInfos.ComponentReferencesPresseMagazine}>
                  <Button className={css(styles.button)}>
                    {t('home:nos-references.boutons.1')}
                  </Button>
                </Link>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link page={pagesInfos.ComponentReferencesPresseRadio}>
                  <Button className={css(styles.button)}>
                    {t('home:nos-references.boutons.2')}
                  </Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </div>
    )
  }
}

export default withNamespaces(['home', 'references'])(NosReferences)
