import React, { Component } from 'react'

import { StyleSheet, css } from 'aphrodite'

import  GoogleMap  from 'inno-components/build/GoogleMap'

const styles = StyleSheet.create({
  map: {},
})
const Map = () => (
  <GoogleMap
    iconUrl="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/favicon/apple-touch-icon-72x72.png"
    center={{ lat: 50.8152376, lng: 4.37139609999997 }}
    containerElement={<div className={css(styles.map)} />}
    loadingElement={<div />}
    mapElement={<div style={{ height: '400px' }} />}
  />
)

export default Map
