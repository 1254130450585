import React from 'react'
import { Button, Paper } from 'inno-components'
import Img from 'gatsby-image'
import { StyleSheet, css } from 'aphrodite'
import Config from '../../Config'

const styles = StyleSheet.create({
  button: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    padding: 0,
    textDecoration: 'none',
    border: 'solid #eee 1px',
    margin: 0,
    ':hover': {
      background: '#000',
    },
  },
  buttonPadd: {
    display: 'block',
    minHeight: '150px',
    backgroundSize: 'cover',
    width: '100%',
    border: 'solid 2px #eee',
  },
  buttonBottom: {
    height: '5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#777',
    backgroundColor: '#fff',
  },
  title: {
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: Config.colors.primary,
    background: '#FAFAFA',
  },
})
const MethodeRaypathButton = ({
  backgroundImage,
  title,
  children,
  config,
  imageSharp,
}) => (
  <Button className={css(styles.button)}>
    {/*
    <div
      className={css(styles.buttonPadd)}
      style={{
        background: `url(${backgroundImage}) no-repeat center`,
      }}
    />
    */}
    {imageSharp && (
      <Img fluid={imageSharp.fluid} className={css(styles.buttonPadd)} />
    )}
    <div className={css(styles.title)}>{title}</div>
    {children && (
      <div className={css(styles.buttonBottom)}>
        <span>{children}</span>
      </div>
    )}
  </Button>
)

export default MethodeRaypathButton
