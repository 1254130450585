import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Scroll } from 'inno-components'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import Helmet from 'react-helmet'

import PrimaryLayout from '../components/layouts/PrimaryLayout'
import Home from '../components/home/Home'
import MethodeRaypath from '../components/home/MethodeRaypath'
import GallerieVideo from '../components/home/GallerieVideo'
import NotreEntreprise from '../components/home/NotreEntreprise'
import NosReferences from '../components/home/NosReferences'
import Map from '../components/home/Map'
import Contact from '../components/home/Contact'

import SetLanguageFromPage from '../config/i18n/SetLanguageFromPage'

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log(`hash : ${hash}`)
      Scroll.scroller.scrollTo(hash)
    }
  }

  render() {
    const { t } = this.props
    const imagesMethode = {
      depoussierage: this.props.data.depoussierage.childImageSharp,
      surfacesAcceptantPeuEau: this.props.data.surfacesAcceptantPeuEau
        .childImageSharp,
      surfacesAcceptantBeaucoupEau: this.props.data.surfacesAcceptantBeaucoupEau
        .childImageSharp,
      sechageEtBrillance: this.props.data.sechageEtBrillance.childImageSharp,
      accessoiresDivers: this.props.data.accessoiresDivers.childImageSharp,
    }

    const entrepriseImages = {
      croixBleue: this.props.data.croixBleue.childImageSharp,
      sunChild: this.props.data.sunChild.childImageSharp,
      associationLemarchal: this.props.data.associationLemarchal
        .childImageSharp,
      energyAwards: this.props.data.energyAwards.childImageSharp,
      bruxellesEnvironnement: this.props.data.bruxellesEnvironnement
        .childImageSharp,
      reductionDesDechets: this.props.data.reductionDesDechets.childImageSharp,
      salonInnovation1: this.props.data.salonInnovation1.childImageSharp,
      salonInnovation2: this.props.data.salonInnovation2.childImageSharp,
      salonInnovation3: this.props.data.salonInnovation3.childImageSharp,
    }

    const referencesImages = {
      anderlecht: this.props.data.anderlecht.childImageSharp,
      audi: this.props.data.audi.childImageSharp,
      autoworld: this.props.data.autoworld.childImageSharp,
      bewell: this.props.data.bewell.childImageSharp,
      bruxellesEnseignement: this.props.data.bruxellesEnseignement
        .childImageSharp,
      bxl: this.props.data.bxl.childImageSharp,
      chateauDeNamur: this.props.data.chateauDeNamur.childImageSharp,
      cleanup: this.props.data.cleanup.childImageSharp,
      collegeSacreCoeur: this.props.data.collegeSacreCoeur.childImageSharp,
      collegeSaintAugustin: this.props.data.collegeSaintAugustin
        .childImageSharp,
      ecoleSaintPierre: this.props.data.ecoleSaintPierre.childImageSharp,
      ew: this.props.data.ew.childImageSharp,
      floreal: this.props.data.floreal.childImageSharp,
      fps: this.props.data.fps.childImageSharp,
      groupeCleaningBio: this.props.data.groupeCleaningBio.childImageSharp,
      holidayInn: this.props.data.holidayInn.childImageSharp,
      institutSaintPaul: this.props.data.institutSaintPaul.childImageSharp,
      ipc: this.props.data.ipc.childImageSharp,
      jansonDeSailly: this.props.data.jansonDeSailly.childImageSharp,
      kafenio: this.props.data.kafenio.childImageSharp,
      laDefense: this.props.data.laDefense.childImageSharp,
      magellan: this.props.data.magellan.childImageSharp,
      maisonDesEtudiantes: this.props.data.maisonDesEtudiantes.childImageSharp,
      martinsHotels: this.props.data.martinsHotels.childImageSharp,
      meetMeat: this.props.data.meetMeat.childImageSharp,
      tubize: this.props.data.tubize.childImageSharp,
    }

    return (
      <PrimaryLayout>
        <Scroll.Element name="accueil" id="accueil">
          <Home />
        </Scroll.Element>
        <Scroll.Element name="methode-raypath" id="methode-raypath">
          <MethodeRaypath images={imagesMethode} />
        </Scroll.Element>
        <Scroll.Element name="galerie-video" id="galerie-video">
          <GallerieVideo />
        </Scroll.Element>
        <Scroll.Element name="notre-entreprise" id="notre-entreprise">
          <NotreEntreprise images={entrepriseImages} />
        </Scroll.Element>
        <Scroll.Element name="nos-references" id="nos-references">
          <NosReferences images={referencesImages} />
        </Scroll.Element>
        <Scroll.Element name="contact" id="contact">
          <Map />
          <Contact />
        </Scroll.Element>
      </PrimaryLayout>
    )
  }
}

const echance = compose(
  pure,
  SetLanguageFromPage()
)

export default echance(IndexPage)

export const pageQuery = graphql`
  query ImageQuery {
    depoussierage: file(relativePath: { eq: "home/depoussierage.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    surfacesAcceptantPeuEau: file(
      relativePath: { eq: "home/surfaces-acceptant-peu-eau.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    surfacesAcceptantBeaucoupEau: file(
      relativePath: { eq: "home/surfaces-acceptant-beaucoup-eau.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sechageEtBrillance: file(
      relativePath: { eq: "home/sechage-et-brillance.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accessoiresDivers: file(
      relativePath: { eq: "home/accessoires-divers.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    croixBleue: file(relativePath: { eq: "home/croix-bleue.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sunChild: file(relativePath: { eq: "home/sun-child.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    associationLemarchal: file(
      relativePath: { eq: "home/association-lemarchal.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    energyAwards: file(relativePath: { eq: "home/energy-awards.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salonInnovation1: file(
      relativePath: { eq: "home/salon-innovation-1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salonInnovation2: file(
      relativePath: { eq: "home/salon-innovation-2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salonInnovation3: file(
      relativePath: { eq: "home/salon-innovation-3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bruxellesEnvironnement: file(
      relativePath: { eq: "home/bruxelles-environnement.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    reductionDesDechets: file(
      relativePath: { eq: "home/reduction-des-dechets.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    anderlecht: file(relativePath: { eq: "references/anderlecht.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    audi: file(relativePath: { eq: "references/audi.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    autoworld: file(relativePath: { eq: "references/autoworld.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bewell: file(relativePath: { eq: "references/be-well.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bruxellesEnseignement: file(
      relativePath: { eq: "references/bruxelles-enseignement.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bxl: file(relativePath: { eq: "references/bxl.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chateauDeNamur: file(
      relativePath: { eq: "references/chateau-de-namur.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cleanup: file(relativePath: { eq: "references/clean-up.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    collegeSacreCoeur: file(
      relativePath: { eq: "references/college-sacre-coeur.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    collegeSaintAugustin: file(
      relativePath: { eq: "references/college-saint-augustin.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ecoleSaintPierre: file(
      relativePath: { eq: "references/ecole-saint-pierre.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ew: file(relativePath: { eq: "references/ew.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floreal: file(relativePath: { eq: "references/floreal.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fps: file(relativePath: { eq: "references/fps.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    groupeCleaningBio: file(
      relativePath: { eq: "references/groupe-cleaning-bio.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    holidayInn: file(relativePath: { eq: "references/holiday-inn.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    institutSaintPaul: file(
      relativePath: { eq: "references/institut-saint-paul.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ipc: file(relativePath: { eq: "references/ipc.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jansonDeSailly: file(
      relativePath: { eq: "references/janson-de-sailly.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kafenio: file(relativePath: { eq: "references/kafenio.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    laDefense: file(relativePath: { eq: "references/la-defense.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    magellan: file(relativePath: { eq: "references/magellan.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maisonDesEtudiantes: file(
      relativePath: { eq: "references/maison-des-etudiantes.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    martinsHotels: file(relativePath: { eq: "references/martins-hotels.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    meetMeat: file(relativePath: { eq: "references/meet-meat.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tubize: file(relativePath: { eq: "references/tubize.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
