import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { TemplateSidebarLeft, MediaQueries } from 'inno-components'
import { StaticQuery, graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import Helmet from 'react-helmet'
import Sidebar from './sidebar/Sidebar'
import Layout from './Layout'

const styles = StyleSheet.create({
  sidebar: {
    width: '100%',
    backgroundColor: '#ccc',
    zIndex: 9,
    [MediaQueries.small]: {
      position: 'fixed',
      width: '22em'
    }
  },
  content: {
    display: 'block',
    paddingTop: 'calc(40.8px + 3em)',
    [MediaQueries.small]: {
      padding: 0,
      marginLeft: '22em'
    }
  }
})

const PrimaryLayout = ({ children, t }) => (
  <React.Fragment>
    <Helmet
      title={t('head.title')}
      meta={[
        {
          name: 'description',
          content: t('head.meta-description')
        }
      ]}
    />
    <StaticQuery
      query={graphql`
        query LayoutImageQuery {
          logo: file(relativePath: { eq: "home/logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 239) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <div style={{ maxWidth: '2000px' }}>
            <TemplateSidebarLeft
              bgColor="#000"
              sidebarWidth="20vw"
              sidebar={<Sidebar logo={data.logo.childImageSharp} />}
              sidebarClassName={css(styles.sidebar)}
              contentClassName={css(styles.content)}
            >
              {children}
            </TemplateSidebarLeft>
          </div>
        </Layout>
      )}
    />
  </React.Fragment>
)

export default withNamespaces('home')(PrimaryLayout)
