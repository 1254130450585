import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { Player, BigPlayButton } from 'video-react'
import {
  Grid, Section, SliderBasic, MediaQueries
} from 'inno-components'

import Config from '../../Config'
import '../../../node_modules/video-react/dist/video-react.css'
import Classes from '../../styles/classes'

import i18next from '../../config/i18n/context'

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 5 } }
]

const styles = StyleSheet.create({
  section: {
    padding: '1em 1.5em 5em 1.5em',
    [MediaQueries.small]: {
      padding: '2em 2em 10em 2em'
    }
  },
  button: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    padding: 0,
    margin: 0
  },
  player: {
    border: Config.colors.primary,
    padding: '1em',
    [MediaQueries.small]: {
      padding: '2em'
    }
  },
  backgroundTop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `linear-gradient(${Config.colors.thirdary}, ${Config.colors.thirdary})`,
    transform: 'skewY(-6deg)',
    transformOrigin: 'top left'
  }
})

export class MethodeRaypath extends Component {
  state = {
    buttonHoveredIndex: -1
  }

  render() {
    const lang = i18next.language || window.localStorage.i18nextLng
    const items = [
      {
        poster: `https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-1-${lang}.png`,
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video01.mp4'
      },
      {
        poster:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-2.png',
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video02.mp4'
      },
      {
        poster:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-3.png',
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video03.mp4'
      },
      {
        poster:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-4.png',
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video04.mp4'
      },
      {
        poster:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-5.png',
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video05.mp4'
      },
      {
        poster:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/videos/raypath-video-6.png',
        src:
          'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/videos/video06.mp4'
      }
    ]

    const covers = items.map(item => (
      <div className={css(styles.player)} key={item.poster}>
        <Player poster={item.poster} preload="none">
          <BigPlayButton position="center" />
          <source src={item.src} />
        </Player>
      </div>
    ))
    return (
      <Section bgColor="#fff">
        <div className={css(styles.backgroundTop)} />
        <div className={css(styles.section)}>
          <SliderBasic
            items={[]}
            responsive={responsive}
            slidesToShow={2}
            slidesToScroll={2}
            dots
            infinite
          >
            {covers}
          </SliderBasic>
        </div>
      </Section>
    )
  }
}

export default MethodeRaypath
