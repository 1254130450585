import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { Material, Typicons, FontAwesome } from 'inno-components/build/Icons'
import swal from 'sweetalert2'

import {
  FormBuilder,
  FormField,
  Signature,
  Grid,
  Section,
  ContactTemplateSection,
  ContactTemplate
} from 'inno-components'

import { sendMail } from '../../config/emails'
import Gdpr from './Gdpr'
import Config from '../../Config'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  section: {
    backgroundColor: '#333333'
  },
  title: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '3em',
    color: '#fff'
  },
  list: {
    color: '#fff',
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  icon: {
    color: '#fff',
    width: '2em'
  },
  marginTop: {
    marginTop: '1em'
  },
  grid: {
    margin: 0
  },
  link: {
    color: '#fff',
    textDecoration: 'none'
  },
  input: {
    padding: '1em',
    fontSize: '1em',
    borderRadius: 0,
    color: '#ffff',
    display: 'block',
    background: 'transparent',
    border: 'solid 2px #fff',
    margin: 0,
    width: '100%',
    '::-webkit-input-placeholder': {
      color: '#fff',
      fontWeight: 'bold'
    }
  },
  inputError: {
    color: '#FF0000',
    border: 'solid 2px #FF0000',
    '::-webkit-input-placeholder': {
      color: '#FF0000',
      fontWeight: 'bold'
    }
  },
  error: {
    color: '#FF0000'
  },
  titleAddress: {
    color: '#fff'
  },
  padd: {
    paddingLeft: '2em'
  },
  columnAddress: {
    backgroundColor: Config.colors.secondary
  },
  item: {
    color: '#fff',
    fontWeight: 'bold'
  },
  marginLeft: {
    marginLeft: '1.6em'
  },
  button: {
    color: '#fff',
    border: 'solid 2px #fff'
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none'
  },
  iconSignature: {
    color: '#FC0D1B'
  },
  checkbox: {
    borderColor: '#fff',
    color: '#fff'
  }
})
class Contact extends Component {
  state = {
    posts: [1, 2, 3, 4]
  }

  componentDidMount() {}

  onSubmit = response => {
    const html = []
    response.values.forEach(value => {
      html.push(`<h3>${value.name == 'consentement-gdpr' ? 'Consentement Gdpr' : value.label}</h3><p>${value.value}</p>`)
    })

    const section = ContactTemplateSection(
      'Nouvelle Demande De Contact',
      html.join('')
    )
    const template = ContactTemplate(
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/favicon/apple-touch-icon-72x72.png',
      section
    )

    sendMail(
      'noreply@raypathpro.com',
      'christophe.denardis@raypathpro.com',
      'Nouveau Contact',
      'innobelge.be',
      template,
      response.files.fichiers
    )
      .then(() => {
        swal(
          'Message Envoyé',
          'Nous reviendrons vers vous très prochainement',
          'success'
        )
      })
      .catch(() => {
        swal(
          'Message Envoyé',
          'Nous reviendrons vers vous très prochainement',
          'success'
        )
      })
  }

  render() {
    const { t } = this.props
    const fields = [
      [
        FormField({
          label: t('home:contact.nom'),
          name: 'societe',
          type: 'text',
          width: 8,
          required: true,
          errorMessage: t('home:contact.messages.required')
        }),
        FormField({
          label: t('home:contact.email'),
          name: 'email',
          type: 'text',
          width: 8,
          required: true,
          errorMessage: t('home:contact.messages.required')
        })
      ],

      [
        FormField({
          label: t('home:contact.informations'),
          name: 'informations-complementaires',
          type: 'textarea',
          width: 16
        })
      ],
      [
        FormField({
          label: <Gdpr />,
          value: 'consentement-gdpr',
          name: 'consentement-gdpr',
          type: 'checkbox',
          width: 16,
          required: true,
          errorMessage: t('home:contact.messages.required')
        })
      ]
    ]

    return (
      <Section className={css(Classes.section, styles.section)}>
        <Grid stackable>
          <Grid.Column width={10}>
            <h3 className={css(styles.title)}>Contact</h3>

            <FormBuilder
              onSubmit={this.onSubmit}
              fields={fields}
              showLabels={false}
              submitLabel={t('home:contact.envoyer')}
              inputClassName={css(styles.input)}
              errorClassName={css(styles.error)}
              inputErrorClassName={css(styles.input, styles.inputError)}
              textAreaClassName={css(styles.input)}
              checkboxClassName={css(styles.checkbox)}
              labelClassName={css(styles.label)}
              buttonSubmitClassName={css(styles.button)}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <h3 className={css(styles.title, styles.titleAddress)}>
              Raypath International
            </h3>
            <ul className={css(styles.list)}>
              <li>
                <Material.MdLocationOn className={css(styles.icon)} />
                Avenue Louise 523
              </li>
              <li className={css(styles.padd)}>1050 Bruxelles</li>
              <li className={css(styles.padd)}>Belgique</li>
              <li className={css(styles.marginTop)}>
                <a
                  href="tel:+3222655286"
                  target="_blank"
                  className={css(styles.link)}
                >
                  <Material.MdPhone className={css(styles.icon)} />
                  +32 2 265 52 86
                </a>
              </li>
              <li>
                <a
                  href="tel:+32472430540"
                  target="_blank"
                  className={css(styles.link)}
                >
                  <Material.MdPhoneAndroid className={css(styles.icon)} />
                  +32 472 43 05 40
                </a>
              </li>
              <li>
                <a
                  style={{ marginLeft: '2em' }}
                  href="tel:+32497608032"
                  target="_blank"
                  className={css(styles.link)}
                >
                  +32 497 60 80 32
                </a>
              </li>
              <li className={css(styles.marginTop)}>
                <Material.MdMail className={css(styles.icon)} />
                <a
                  href="mailto:info@raypathinternational.com"
                  className={css(styles.link)}
                >
                  info@raypathpro.com
                </a>
              </li>
              <li>
                <FontAwesome.FaSkype className={css(styles.icon)} /> Call Us
              </li>
              <li>
                <a
                  href="https://www.facebook.com/RaypathInternational"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={css(styles.link)}
                >
                  <FontAwesome.FaFacebookSquare className={css(styles.icon)} />{' '}
                  facebook.com/RaypathInternational
                </a>
              </li>
            </ul>
          </Grid.Column>
          <Grid.Column width={16}>
            <div style={{ padding: '2em', textAlign: 'center' }}>
              <Signature
                className={css(styles.signature)}
                iconClassName={css(styles.iconSignature)}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Section>
    )
  }
}

export default withNamespaces('home')(Contact)
