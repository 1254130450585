import axios from 'axios'

const BASE_URL = 'https://www.aliaslanguages.be'

export const BASE_PATH = '/api/emails'

export const sendMail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData()
  data.append('from', from)
  data.append('to', to)
  data.append('subject', subject)
  data.append('domain', domain)
  data.append('html', html)

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement)
    })
  }
  return axios.post('https://us-central1-innobelge-websites.cloudfunctions.net/emails', data)
}
