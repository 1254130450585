import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { Material, Typicons, FontAwesome } from 'inno-components/build/Icons'
import { Grid, Section, Paper, Button, MediaQueries } from 'inno-components'
import EarthIcon from '../../icons/Earth'

import Config from '../../Config'
import MethodeRaypathButton from './MethodeRaypathButton'
import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  section: {},
  sectionProduits: {
    backgroundColor: Config.colors.thirdary,
  },
  sectionFeatures: {
    backgroundColor: '#fff',
    paddingTop: '2em',
    paddingBottom: '8em',
  },
  sectionAssets: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: Config.colors.thirdary,
  },
  header: {
    fontSize: '1.2em',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: Config.colors.primary,
    textAlign: 'center',
    marginTop: '2em',
    [MediaQueries.small]: {
      fontSize: '1.7em',
      lineHeight: '2.3em',
      margin: 0,
    },
  },
  link: {
    textDecoration: 'none',
  },
  subheader: {
    display: 'block',
    fontSize: '1.3em',
    lineHeight: '1.3em',
    margin: '0.5em 0',
    fontWeight: 'bold',

    color: Config.colors.thirdary,
    [MediaQueries.small]: {
      fontSize: '1.7em',
    },
  },
  grid: {},
  column: {},
  player: {
    border: `solid 15px ${Config.colors.primary}`,
  },
  button: {
    display: 'block',
    width: '100%',
    color: '#fff',
    padding: '1em',
    fontSize: '1.3em',
    backgroundColor: Config.colors.primary,
    margin: '2em 0 5em 0',
  },
  icon: {
    fontSize: '1.2em',
    marginRight: '0.7em',
  },
  buttonHypothese: {
    height: '75px',
    backgroundColor: 'rgba(0,0,0,0)',
    padding: '0.2em',
    margin: 0,
    color: '#fff',
    border: 'solid 3px #fff',
    fontSize: '1.1em',
    fontWeight: 'extra-bold',
    width: '100%',
    textAlign: 'center',
  },
  caption: {
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2em',
    fontWeight: 800,
    lineHeight: '1.3em',
    marginBottom: '0.5em',
    [MediaQueries.small]: {
      lineHeight: '1.5em',
      marginBottom: '1.5em',
      fontSize: '3em',
    },
  },
  triangle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `linear-gradient(#f5f9fb, #f5f9fb)`,
    transform: 'skewY(-6deg)',
    transformOrigin: 'top left',
  },
  container: {
    position: 'relative',
    height: '500px',
  },
  subtitle: {
    display: 'block',
    fontSize: '1.5em',
    textTransform: 'uppercase',
    color: Config.colors.primary,
  },
  feature: {
    lineHeight: '2em',
    fontSize: '1em',
    height: '100%',
    boxShadow: 'none',
    borderRadius: '0.5em',
    textAlign: 'center',
    [MediaQueries.small]: {
      textAlign: 'left',
    },
  },
})
export class MethodeRaypath extends Component {
  render() {
    const { t, tReady } = this.props
    return (
      <>
        <Section>
          <div className={css(Classes.section)}>
            <Grid className={css(styles.grid)} columns={3} stackable>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h2 className={css(styles.header)}>
                    {t('methode-raypath.sous-titre')}
                    <span className={css(styles.subheader)}>
                      {t('methode-raypath.titre')}
                    </span>
                  </h2>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Section>
        <Section className={css(styles.sectionFeatures)}>
          <div className={css(Classes.section)}>
            <Grid className={css(styles.grid)} columns={3} stackable>
              <Grid.Row column={3}>
                <Grid.Column>
                  <div className={css(styles.feature)}>
                    <h4 style={{ color: Config.colors.secondary }}>
                      Raypath
                      <span className={css(styles.subtitle)}>
                        {t('methode-raypath.pour-qui.titre')}
                      </span>
                    </h4>
                    <ul
                      style={{
                        padding: 0,
                        listStyleType: 'none',
                      }}
                    >
                      <li>{t('methode-raypath.pour-qui.description.0')}</li>
                      <li>{t('methode-raypath.pour-qui.description.1')}</li>
                      <li>{t('methode-raypath.pour-qui.description.2')}</li>
                      <li>{t('methode-raypath.pour-qui.description.3')}</li>
                      <li>{t('methode-raypath.pour-qui.description.4')}</li>
                      <li>{t('methode-raypath.pour-qui.description.5')}</li>
                    </ul>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={css(styles.feature)}>
                    <h4 style={{ color: Config.colors.secondary }}>
                      Raypath
                      <span className={css(styles.subtitle)}>
                        {t('methode-raypath.pourquoi.titre')}
                      </span>
                    </h4>
                    <p style={{ lineHeight: '2em' }}>
                      {t('methode-raypath.pourquoi.description')}
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={css(styles.feature)}>
                    <h4 style={{ color: Config.colors.secondary }}>
                      Raypath
                      <span className={css(styles.subtitle)}>
                        {t('methode-raypath.comment.titre')}
                      </span>
                    </h4>
                    <p
                      style={{ lineHeight: '2em' }}
                      dangerouslySetInnerHTML={{
                        __html: t('methode-raypath.comment.description'),
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Section>
        <Section className={css(styles.sectionProduits)}>
          <div className={css(Classes.section)}>
            <div className={css(styles.triangle)} />
            <Grid className={css(styles.grid)} columns={5} doubling centered>
              <Grid.Row>
                <Grid.Column className={css(styles.column)}>
                  <Link page={pagesInfos.ComponentMethodeRaypathDepoussierage}>
                    <MethodeRaypathButton
                      title={t('methode-raypath.produits.0.titre')}
                      imageSharp={this.props.images.depoussierage}
                    >
                      {t('methode-raypath.produits.0.sous-titre')}
                    </MethodeRaypathButton>
                  </Link>
                </Grid.Column>
                <Grid.Column className={css(styles.column)}>
                  <Link
                    page={
                      pagesInfos.ComponentMethodeRaypathSurfacesAcceptantPeuEau
                    }
                  >
                    <MethodeRaypathButton
                      title={t('methode-raypath.produits.1.titre')}
                      imageSharp={this.props.images.surfacesAcceptantPeuEau}
                    >
                      {t('methode-raypath.produits.1.sous-titre')}
                    </MethodeRaypathButton>
                  </Link>
                </Grid.Column>
                <Grid.Column className={css(styles.column)}>
                  <Link
                    page={
                      pagesInfos.ComponentMethodeRaypathSurfacesAcceptantBeaucoupEau
                    }
                  >
                    <MethodeRaypathButton
                      title={t('methode-raypath.produits.2.titre')}
                      imageSharp={
                        this.props.images.surfacesAcceptantBeaucoupEau
                      }
                    >
                      {t('methode-raypath.produits.2.sous-titre')}
                    </MethodeRaypathButton>
                  </Link>
                </Grid.Column>
                <Grid.Column className={css(styles.column)}>
                  <Link
                    page={pagesInfos.ComponentMethodeRaypathSechageEtBrillance}
                  >
                    <MethodeRaypathButton
                      title={t('methode-raypath.produits.3.titre')}
                      imageSharp={this.props.images.sechageEtBrillance}
                    >
                      {t('methode-raypath.produits.3.sous-titre')}
                    </MethodeRaypathButton>
                  </Link>
                </Grid.Column>
                <Grid.Column className={css(styles.column)}>
                  <Link
                    page={pagesInfos.ComponentMethodeRaypathAccessoiresDivers}
                  >
                    <MethodeRaypathButton
                      title={t('methode-raypath.produits.4.titre')}
                      imageSharp={this.props.images.accessoiresDivers}
                    >
                      {t('methode-raypath.produits.4.sous-titre')}
                    </MethodeRaypathButton>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid centered>
              <Grid.Row>
                <Grid.Column computer={10} mobile={14}>
                  <Link page={pagesInfos.ComponentMethodeRaypath}>
                    <Button className={css(styles.button)} fontWeight="bold">
                      {t('methode-raypath.en-savoir-plus')}
                    </Button>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Section>

        <Section className={css(Classes.section, styles.sectionAssets)}>
          <Grid stackable>
            <Grid.Row centered>
              <Grid.Column width={16}>
                <p className={css(styles.caption)}>
                  {t('methode-raypath.accroche')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={3}>
              <Grid.Column>
                <Link
                  page={pagesInfos.ComponentAssetsEconomiquementIntelligent}
                >
                  <Button
                    className={css(styles.buttonHypothese)}
                    fontWeight="bold"
                    variant="contained"
                  >
                    <FontAwesome.FaCoins className={css(styles.icon)} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('methode-raypath.economiquement-intelligent'),
                      }}
                    />
                  </Button>
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Link
                  page={
                    pagesInfos.ComponentAssetsEnvironnementalementResponsable
                  }
                >
                  <Button
                    className={css(styles.buttonHypothese)}
                    fontWeight="bold"
                  >
                    <EarthIcon className={css(styles.icon)} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'methode-raypath.environnementalement-responsable'
                        ),
                      }}
                    />
                  </Button>
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Link page={pagesInfos.ComponentAssetsSocialementRespectueux}>
                  <Button
                    className={css(styles.buttonHypothese)}
                    fontWeight="bold"
                  >
                    <FontAwesome.FaUserFriends className={css(styles.icon)} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('methode-raypath.socialement-respectueux'),
                      }}
                    />
                  </Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </>
    )
  }
}

export default withNamespaces('home')(MethodeRaypath)
