import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import {
  Button, ImageBasic, MaterialDialog, MediaQueries
} from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Img from 'gatsby-image'
import Config from '../../Config'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  reference: {
    display: 'flex',
    minHeight: '250px',
    padding: '0 1em',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonOpen: {
    color: Config.colors.primary,
    fontWeight: 'bold',
    backgroundColor: '#eee',
    margin: '1em 0'
  },
  buttonClose: {
    margin: '1em 0'
  },
  image_container: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%' /* 1:1 Aspect Ratio */,
    border: 'solid 3px #EFEFEF',
    backgroundColor: '#fff',
    borderRadius: '1em'
  },
  image_content: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    [MediaQueries.small]: {
      padding: '3em'
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  }
})
export class NosReferencesItemPure extends Component {
  state = {
    open: false
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <div className={css(styles.reference)}>
          <div className={css(styles.image_container)}>
            <div className={css(styles.image_content)}>
              <Img fluid={this.props.image.fluid} className={css(styles.image)} />
            </div>
          </div>
          <Button className={css(styles.buttonOpen)} onClick={this.handleClickOpen}>
            {t('home:nos-references.voir')}
          </Button>
        </div>
        <MaterialDialog open={this.state.open} scroll="body">
          <div style={{ padding: '3em' }}>
            <h3>{this.props.reference.title}</h3>
            {this.props.reference.messages
              && this.props.reference.messages.map((content) => {
                const Content = content.message.map(message => (
                  <div>
                    <p>{message}</p>
                  </div>
                ))
                let Signature = <div />
                if (content.signature) {
                  Signature = (
                    <ul
                      style={{
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0
                      }}
                    >
                      <li
                        style={{
                          fontWeight: 'bold',
                          marginTop: '2em',
                          marginBottom: '0.5em'
                        }}
                      >
                        {content.signature.title}
                      </li>
                      {content.signature.description.map(des => (
                        <li>{des}</li>
                      ))}
                    </ul>
                  )
                }
                return (
                  <div>
                    <p
                      style={{
                        fontWeight: 'bold',
                        margin: '1em 0'
                      }}
                    >
                      {content.date}
                    </p>
                    <div>{Content}</div>
                    <div>{Signature}</div>
                  </div>
                )
              })}
          </div>
          <div
            style={{
              padding: '1.5em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={this.handleClose}
              className={css(Classes.primaryButton, styles.buttonClose)}
            >
              {t('home:nos-references.fermer')}
            </Button>
          </div>
        </MaterialDialog>
      </React.Fragment>
    )
  }
}

export default withNamespaces('home')(NosReferencesItemPure)
