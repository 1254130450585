import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { Grid, Section, ImageBasic } from 'inno-components'
import Img from 'gatsby-image'

import Config from '../../Config'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  title: {
    fontSize: '1.5em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    color: Config.colors.primary,
  },
  section: {},
  h3: {
    fontSize: '1.5em',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    color: Config.colors.primary,
  },
  subtitle: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: '#283B70',
  },
  padd: {
    paddingBottom: '10em',
  },
  player: {
    margin: '4em',
    border: 'solid 5px #000',
  },
  highlight: {
    fontSize: '1.3em',
    margin: '3em 0',
    display: 'block',
    textAlign: 'center',
  },
})
const NotreEntreprise = ({ t, images }) => (
  <Section bgColor="#fff" className={css(Classes.section)}>
    <div className={css(styles.padd)}>
      <h2 className={css(Classes.h2)}>{t('notre-entreprise.titre')}</h2>
      <h3 className={css(Classes.h3)}>
        {t('notre-entreprise.sections.0.titre')}
      </h3>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.0'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.1'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.2'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.3'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.4'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.paragraphes.5'),
        }}
      />
      <p
        className={css(Classes.headline, Classes.bold)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.0.accroche'),
        }}
      />

      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.1.sous-titres.0')}
      </h4>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.1.paragraphes.0'),
        }}
      />
      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.1.sous-titres.1')}
      </h4>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.1.paragraphes.1'),
        }}
      />
      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.1.sous-titres.2')}
      </h4>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.1.paragraphes.2'),
        }}
      />
      <p
        className={css(Classes.headline)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.1.accroche'),
        }}
      />
      <h3 className={css(Classes.h3)}>
        {t('notre-entreprise.sections.2.titre')}
      </h3>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.2.paragraphes.0'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.2.paragraphes.1'),
        }}
      />
      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.2.sous-titres.0')}
      </h4>
      <Grid className={css(styles.grid)}>
        <Grid.Row centered columns={1}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.reductionDesDechets.fluid}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.2.paragraphes.2'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.2.paragraphes.3'),
        }}
      />
      <Grid className={css(styles.grid)}>
        <Grid.Row centered columns={1}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.bruxellesEnvironnement.fluid}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.2.sous-titres.1')}
      </h4>
      <Grid>
        <Grid.Row centered columns={1}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.energyAwards.fluid}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h4 className={css(Classes.h4)}>
        {t('notre-entreprise.sections.2.sous-titres.2')}
      </h4>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.salonInnovation1.fluid}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.salonInnovation2.fluid}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.salonInnovation3.fluid}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.2.sous-titres.3'),
        }}
      />
      <h3 className={css(Classes.h3)}>
        {t('notre-entreprise.sections.3.titre')}
      </h3>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.3.paragraphes.0'),
        }}
      />
      <Grid className={css(styles.grid)} stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.associationLemarchal.fluid}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.sunChild.fluid}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <Img
                className={css(Classes.image)}
                fluid={images.croixBleue.fluid}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h3 className={css(Classes.h3)}>
        {t('notre-entreprise.sections.4.titre')}
      </h3>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.4.paragraphes.0'),
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('notre-entreprise.sections.4.paragraphes.1'),
        }}
      />
      <p className={css(Classes.paragraph)}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notre-entreprise.sections.4.paragraphes.2'),
          }}
        />{' '}
        <a href="mailto:info@raypathinternational.com">
          info@raypathinternational.com
        </a>
      </p>
    </div>
  </Section>
)

export default withNamespaces('home')(NotreEntreprise)
