import React from 'react'
import { Button, MaterialDialog } from 'inno-components'
import { withNamespaces } from 'react-i18next'

class ScrollDialog extends React.Component {
  state = {
    open: false
  }

  handleClickOpen = (e) => {
    e.preventDefault()
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

    render() {
    const { t } = this.props
    return (
      <div>
        <p style={{ color: '#fff' }}>{t('home:contact.gdpr.caption')}{' '}   <a style={{
          fontWeight: 'bold', textDecoration: 'underline'
        }}onClick={ this.handleClickOpen } > { t('home:contact.gdpr.button') }</a>.</p>


        <MaterialDialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <div
            dangerouslySetInnerHTML={{ __html: t('home:contact.gdpr.contenu') }}
            style={{ padding: '2em' }}
          />
        </MaterialDialog>
      </div>
    )
  }
}

export default withNamespaces('home')(ScrollDialog)
