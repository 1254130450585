import React, { Component } from 'react'
import { compose, pure } from 'recompose'
import { translate, withNamespaces } from 'react-i18next'
import Img from 'gatsby-image'
import {
  MenuItem, MenuVertical, MediaQueries, Button, Flags
} from 'inno-components'
import {
  Link, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll'
import { StyleSheet, css } from 'aphrodite'
import { Material } from 'inno-components/build/Icons'
import Config from '../../../Config'
import Languages from './Languages'
import i18n from '../../../config/i18n/context'
import SetLanguageFromPage from '../../../config/i18n/SetLanguageFromPage'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    padding: '0.5em',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 3,
    background: `${
      Config.colors.primary
    } url(https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/bg_sidebar.png)`,
    [MediaQueries.small]: {
      height: '100%',
      position: 'static',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3em 0em',
      width: '100%',
      boxShadow: '-1px 2px 5px 1px rgba(0, 0, 0, 0.7)'
    }
  },
  menu: {
    flex: 1
  },
  link: {
    display: 'block',
    margin: '1em 0',
    color: '#FFF',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '1.2em',
    cursor: 'pointer',
    transition: 'color 0.5s',
    ':hover': {
      color: Config.colors.secondary
    }
  },
  active: {
    color: Config.colors.secondary
  },
  logoContainer: {
    display: 'block',
    padding: '1em 2em',
    width: '200px',
    [MediaQueries.small]: {
      padding: '1em 4em',
      width: '100%'
    }
  },
  logo: {
    display: 'block',
    width: '100%',
    height: 'auto'
  },
  icon: {
    color: '#FFF'
  }
})

const offset = 1

const LinkComponent = ({ link, active }) => (
  <Link
    to={link.name}
    className={css(styles.link)}
    spy
    activeClass={css(styles.active)}
    smooth
    offset={offset}
  >
    {link.label}
  </Link>
)

class Sidebar extends Component {
  componentDidMount() {
    scrollSpy.update()
  }

  render() {
    const { logo, t } = this.props
    const links = [
      MenuItem({
        anchor: 'accueil',
        name: 'accueil',
        label: t('menu:accueil'),
        offset,
        LinkComponent
      }),
      MenuItem({
        anchor: 'methode-raypath',
        name: 'methode-raypath',
        label: t('menu:methode-raypath'),
        offset,
        LinkComponent
      }),
      MenuItem({
        anchor: 'galerie-video',
        name: 'galerie-video',
        label: t('menu:galerie-video'),
        offset,
        LinkComponent
      }),
      MenuItem({
        name: 'notre-entreprise',
        anchor: 'notre-entreprise',
        label: t('menu:notre-entreprise'),
        offset,
        LinkComponent
      }),
      MenuItem({
        name: 'nos-references',
        anchor: 'nos-references',
        label: t('menu:nos-references'),
        offset,
        LinkComponent
      }),
      MenuItem({
        name: 'contact',
        anchor: 'contact',
        label: t('menu:contact'),
        offset,
        LinkComponent
      })
    ]
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.logoContainer)}>
          <Img fluid={logo.fluid} className={css(styles.logo)} />
        </div>
        <MenuVertical
          className={css(styles.menu)}
          links={links}
          bgOverlayColor="rgba(40, 59, 112,0.95)"
          IconCloseComponent={<Material.MdClose className={css(styles.icon)} />}
          IconOpenComponent={<Material.MdMenu className={css(styles.icon)} />}
        />
        <Languages />
      </div>
    )
  }
}

export default withNamespaces('menu')(Sidebar)
